import { FC, ReactNode } from "react";
import './chart-custom.css';
import { Bar, Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Filler,
    Title,
    Tooltip,
    Legend
  } from 'chart.js';


interface Dataset {
    label: string;
    data: number[];
    backgroundColor: string;
    borderColor?: string;
    fill?: boolean;
}

export interface DataChart {
  labels: string[];
  datasets: Dataset[];
}

interface ChartCustomProps {
    title: string;
    type: 'bar' | 'line';
    data?: DataChart;
    children?: ReactNode;
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Filler,
    // Title,
    Tooltip,
    Legend
  );

export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
        display: false
      },
      
    //   title: {
    //     display: true,
    //     text: 'Chart.js Bar Chart',
    //   },
    },
  };
  
  const ChartCustom: FC<ChartCustomProps> = ({ title, data, type, children }) => {
    return (
        <div className="chart_bar">
            <h4 className="chart_bar_title">
                {title}
            </h4>
            {
              data &&
              <div className="chart_bar_graphics">
                  {type === 'bar' && <div className="info">
                      <Bar options={options} data={data} />
                      <div className="info_title">
                        {data.datasets.map(dataset => <>
                        <div className="info_title_dataset">
                          <div className="info_title_color" style={{ background: dataset.backgroundColor }}></div>
                          <p className="info_title_text">{ dataset.label }</p>
                        </div>
                          
                        </>)}
                      </div>
                  </div>}
                  {type === 'line' && <Line options={options} data={data} />}
              </div>
            }
            {children}
        </div>
    );
}
 
export default ChartCustom;