import { useState } from "react";
import CardCustom from "../../components/card";
import TableCustom, { TableCustomProps } from "../../components/table/table";
import PaginationCustom from "../../components/pagination";
import { LottiesComponent } from "../../components/loadingLotties/LottiesComponent";
import cardLottie from "../../assets/lottie/target.json";
import useFetchTransactions from "../../service/userTransactions/getTransaction";
import { Transactions } from "../../const/transaction";
import TableMaterial from "../../components/tableMaterial/tableMaterial";
import ButtonUI from "../../components/button/buttons";
import { Stack } from "@mui/material";
import AccountFormulary from "../../components/withdrawals";
import { BagStatusCard } from "../../components/bags/bagsStatusCard/BagStatusCard";

const transformDataToTable = (
  transactions: Transactions[]
): TableCustomProps["rows"] => {
  const translations: { [key: string]: string } = {
    APPROVED: "Aprobado",
    DECLINED: "Rechazado",
    PENDING: "Pendiente",
  };

  const translateStatus = (status: string): string => {
    return translations[status] || status;
  };

  return transactions.map((transaction) => ({
    name: transaction.full_name,
    company: transaction.card_brand,
    activity: transaction.description,
    email: transaction.email,
    amount: transaction.amount,
    country: transaction.currency,
    status: (
      <div className={`status ${transaction.status.toLowerCase()}`}>
        {translateStatus(transaction.status)}
      </div>
    ),
  }));
};

const BankAccount = () => {
  const storedToken = sessionStorage.getItem("userData");
  const { transactions, loading, error } = useFetchTransactions(
    storedToken || "", 0, 5, []
  );

  const [page, setPage] = useState(1);
  const itemsPerPage = 8;

  const dataTable: TableCustomProps = {
    columns: [
      { key: "name", label: "Nombre" },
      { key: "company", label: "Empresa" },
      { key: "activity", label: "Actividad" },
      { key: "email", label: "Correo" },
      { key: "amount", label: "Cantidad" },
      { key: "country", label: "País" },
      { key: "status", label: "Status" },
    ],
    rows: transformDataToTable(transactions),
  };

  const totalPages = Math.ceil(dataTable.rows.length / itemsPerPage);
  const paginatedRows = dataTable.rows.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const hasData = dataTable.rows.length > 0;

  const columns: any = [
    { id: "create", label: "Creado" },
    { id: "name", label: "Nombre", align: "right" },
    { id: "account", label: "Cuenta", align: "right" },
    { id: "typeaccount", label: "Tipo de cuenta", align: "right" },
    { id: "bank", label: "Banco", align: "right" },

    {
      id: "action",
      label: "Estado",
      align: "center",
      render: (value: any) => <BagStatusCard status={value}/>,
    },
  ];

  const rows = [
    {
      create: "01/11/2024",
      name: 'JORGE TEST',
      account: 111111111111111,
      typeaccount: 'BANCO',
      bank: 'BANCOMER',
      action: "succes",
    },
    {
        create: "01/11/2024",
        name: 'JORGE TEST',
        account: 111111111111111,
        typeaccount: 'BANCO',
        bank: 'BANCOMER',
        action: "otra",
      },


    // Más filas aquí...
  ];

  return (
    <div>
      {hasData ? (
        <div>
          <h2>Cuentas Bancarias</h2>
          <Stack spacing={2} direction="row">
            <ButtonUI size='small' color='secondary' variant="outlined" title="Descargar Reporte" />
            <AccountFormulary></AccountFormulary>
          </Stack>
          <TableMaterial columns={columns} rows={rows} />;
        </div>
      ) : (
        <LottiesComponent
          style={{ width: "400px" }}
          title="Aun no tienes transacciones. Integra nuestra API en tu sitio y administra tus primeras transacciones"
          lotties={cardLottie}
        />
      )}
    </div>
  );
};

export default BankAccount;
