import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import Auth0ProviderWithNavigate from "./Auth0ProviderWithNavigate";
import LoadingScreen from "./loading/loading";
import useAuthToken from "./service/userAuth/useAuth";
import "react-datepicker/dist/react-datepicker.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

const Main: React.FC = () => {
  const { token, loading } = useAuthToken(clientId || "");
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (!loading && token) {
      console.log("Token fetched:");
    } else if (!loading) {
      console.log("Token is still null");
    }
    setIsInitialized(true);
  }, [loading, token]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return (
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <App />
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  );
};

root.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>
);

reportWebVitals();
