import { FC, ReactNode } from "react";
import './card.css'

interface CardProps {
    children?: ReactNode,
    classCustom?: string;
}
 
const CardCustom: FC<CardProps> = ({ children, classCustom }) => {
    return (
        <div className={`card ${classCustom}`}>
            {children}
        </div>
    );
}
 
export default CardCustom;