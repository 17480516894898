import { FC, useState } from "react";
import CardCustom from "../../components/card";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import "./settings.css";

const Settings: FC = () => {
  const [age, setAge] = useState("Espanol");

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };
  return (
    <div className="main-setting">
      <CardCustom classCustom="card_language"></CardCustom>
      <CardCustom classCustom="card_language">
        <h4>Idioma</h4>

        <FormControl sx={{ m: 1, minWidth: 500 }} size="small">
          <InputLabel id="demo-select-small-label">Idioma</InputLabel>
          <Select
            fullWidth
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={age}
            label="Idioma"
            onChange={handleChange}
          >
            <MenuItem value={"Espanol"}>Espanol</MenuItem>
          </Select>
        </FormControl>
      </CardCustom>
    </div>
  );
};

export default Settings;
