import { FC } from "react";
import { CustomRouter } from "../../model/custom-router.model";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './navbar.css';

interface NavBarCustomProps {
    routers: CustomRouter[];
}
 
const NavBarCustom: FC<NavBarCustomProps> = ({ routers }) => {
    return (
        <nav className="menu">
            <ul className="options">
                {routers.map((option, index) => {
                return (
                    <li className="option" key={index}>
                    {option.to && (
                        <NavLink
                        to={option.to}
                        className={({ isActive }) =>
                            isActive ? "link link-active" : "link"
                        }
                        >
                        {option.icon && (
                            <FontAwesomeIcon icon={option.icon} className="icon" />
                        )}
                        <span className="text">{option.text}</span>
                        </NavLink>
                    )}
                    {option.click && (
                        <button className="link" onClick={option.click}>
                        {option.text}
                        </button>
                    )}
                    </li>
                );
                })}
            </ul>
        </nav>
    );
}
 
export default NavBarCustom;