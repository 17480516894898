import { FC, useState } from "react";
import CardCustom from "../../components/card";
import {
  Avatar,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import './integration.css'
import userFetchProfile from "../../service/userProfile/userProfile";

const Integration: FC = () => {
  const { user } = useAuth0();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };




  const storedToken = sessionStorage.getItem("userData");

  const { profile, loading, error } = userFetchProfile(storedToken || "");
  
  return (
    <div>
      <CardCustom classCustom="card-integration-custom">
        <Typography>
          Es importante que no exponga su clave privada en ninguna parte de su
          programa, solo debe usarse desde su servidor.
        </Typography>
      </CardCustom>
      <CardCustom classCustom="card-profile-custom">
        <Avatar
          sx={{ width: 52, height: 52, marginBottom: "10px" }}
          src={user?.picture}
        ></Avatar>
        <Typography>{user?.name}</Typography>
        <FormControl sx={{ m: 1, width: "80%", background:'white' }} variant="outlined">
          <OutlinedInput
            value={profile?.data?.response?.data?.static_token}
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
      </CardCustom>

      {/* <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Advanced settings
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              Filtering has been entirely disabled for whole web server
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer
              sit amet egestas eros, vitae egestas augue. Duis vel est augue.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Personal data
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer
              sit amet egestas eros, vitae egestas augue. Duis vel est augue.
            </Typography>
          </AccordionDetails>
        </Accordion> */}
    </div>
  );
};

export default Integration;
