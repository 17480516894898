import { FC } from "react";

import './header.css';
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { MenuButton } from "../../button/MenuButton";


const CustomHeader: FC = () => {
    const { user } = useAuth0();

const OnclickMenu = (event: any) => {

}

    return <header className="header">
        <h2 className="title">Bienvenido</h2>
        <div className="lenguege">ES</div>
        {/* <div className="notifications">
            <FontAwesomeIcon icon={ faBell} />
            <div className="alert"></div>
        </div> */}
        <div className="user">
            <MenuButton handleClick={OnclickMenu}  name={user?.name} picture={user?.picture}/>


        </div>
    </header>;
}
 
export default CustomHeader;