import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import TableMaterial from "../../components/tableMaterial/tableMaterial";
import useFetchTransference from "../../service/userTransference/getTranference";
import { TableCustomProps } from "../../components/table/table";

const Tranference = () => {

  const storedToken = sessionStorage.getItem("userData");

  const { transference, loading, error } = useFetchTransference(
    storedToken || ""
  );

  const transformDataToTable = (
    transferences: any[]
  ): TableCustomProps["rows"] => {

  
    return transferences?.map((transaction) => ({
  
      name: transaction.name,
      company: transaction.email,
      clabe: transaction.clabe,
      date: new Date(transaction?.created_at).toLocaleDateString('es-MX', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      })
      ,
  
      // status: (
      //   <div className={`status ${transaction.status.toLowerCase()}`}>
      //     {translateStatus(transaction.status)}
      //   </div>
      // ),
    }));
  };


  const dataTable: any = {
    columns: [
      { id: "name", label: "Nombre" },
      { id: "company", label: "Correo" },
      { id: "clabe", label: "CLABE" },
      { id: "date", label: "Fecha de creación" },
      { id: "status", label: "Status" },
    ],
    rows: transformDataToTable(transference?.data[0]?.clients),
  };



  return (
    <div>
      <Container maxWidth='xl'>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Paper elevation={2}>
              <Box px={4}>
                <Typography variant="h6" color='green' mt={1}>
                 {'  Tu cuenta CLABE es: ' + transference?.data[0]?.main_clabe}
                </Typography>
          
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TableMaterial report={true} columns={dataTable.columns} rows={ dataTable?.rows || []} id='table-transference' />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Tranference;
