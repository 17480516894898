import { Chip, Stack } from '@mui/material';

export const BagStatusCard = ({status}: any) => {
    return (
        <Stack spacing={1} sx={{ alignItems: 'center' }}>
          <Stack direction="row" spacing={1}>
            <Chip label="Activa" color={status === 'succes'? "primary" : 'error'} />
          </Stack>
        </Stack>
      );
}
