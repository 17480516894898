import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardBasicCustomProps } from "../../model/custom-card-basic";
import "./card-basic.css";
import TooltipUI from "../toltip/tooltip";

const CardBasicCustom: FC<CardBasicCustomProps> = ({
  iconTooltip,
  titleTooltip,
  icon,
  value,
  description,
  info,
  classCustom,
  ...prop
}) => {
  return (
    <div className={"card_basic " + classCustom} {...prop}>
      {icon && (
        <div className="card_basic_container">
          <div className="card_basic__logo">
            <FontAwesomeIcon icon={icon} />
          </div>
          <div>
            <TooltipUI title={titleTooltip}  icon={iconTooltip} />
          </div>
        </div>
      )}

      {value && <p className="card_basic__value">{value}</p>}
      {description && <p className="card_basic__description">{description}</p>}
      {info && <p className="card_basic__info">{info}</p>}
    </div>
  );
};

export default CardBasicCustom;
