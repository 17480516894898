import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import "./App.css";
import AppRoutes from "./pages/routes";
import { useEffect, useState } from "react";
import useAuthToken from "./service/userAuth/useAuth";
import LoadingScreen from "./loading/loading";

function App() {
  const { logout } = useAuth0();
  const { loading } = useAuthToken(process.env.REACT_APP_AUTH0_CLIENT_ID || "");
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [logoutTriggered, setLogoutTriggered] = useState<boolean>(false);

  useEffect(() => {
    if (!loading) {
      const isLoggedIn = sessionStorage.getItem("authFlag");

      if (!isLoggedIn) {
        if (!logoutTriggered) {
          setLogoutTriggered(true);
          logout({ logoutParams: { returnTo: window.location.origin } });
        }
        return;
      }

      setIsInitialized(true);
    }
  }, [loading, logout, logoutTriggered]);

  if (loading || !isInitialized) {
    return <LoadingScreen />;
  }

  return <AppRoutes />;
}

export default withAuthenticationRequired(App);
