import React, { useEffect, useState } from "react";
import { TextField, Button, Container, Stack } from "@mui/material";
import axios from "axios";
import SnackBar from "../../components/notification/snackbar/snackbar";
const apiUrl = process.env.REACT_APP_API_URL;


export const FormEnterprise = ({ data }: any) => {

  const userData = data?.data?.data || {};
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const [enterpriseName, setEnterpriseName] = useState("");
  const [socialReason, setSocialReason] = useState("");
  const [enterprisePhone, setEnterprisePhone] = useState("");
  const [enterpriseEmail, setEnterpriseEmail] = useState("");
  const [rfc, setRfc] = useState("");
  const [street, setStreet] = useState("");
  const [extNumber, setExtNumber] = useState("");
  const [intNumber, setIntNumber] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");


  useEffect(() => {
    if (data?.data) {
      const userData = data?.data?.data || {};
      const addressData = userData?.enterprise || {};
      
      setEnterpriseName(addressData?.enterprise_name || '');
      setSocialReason(addressData?.social_reason || '');
      setEnterprisePhone(addressData?.enterprise_phone || '');
      setEnterpriseEmail(addressData?.enterprise_email || '');
      setRfc(addressData?.rfc || '')
      setStreet(addressData?.address?.street || '')
      setExtNumber(addressData?.address?.ext_number|| '')
      setIntNumber(addressData?.address?.int_number|| '')
      setNeighborhood(addressData?.address?.neighborhood|| '')
      setCity(addressData?.address?.city|| '')
      setState(addressData?.address?.state|| '')
      setCountry(addressData?.address?.country|| '')
      setPostalCode(addressData?.address?.postal_code|| '')

    }
  }, [data]);




  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    

    const profileData = {
      email: userData.email,
      enterprise: {
        
        enterprise_name: enterpriseName,
        social_reason: socialReason,
        enterprise_phone: enterprisePhone,
        enterprise_email: enterpriseEmail,
        rfc: rfc,
        address: {
          street: street,
          ext_number: extNumber,
          int_number: intNumber,
          neighborhood: neighborhood,
          city: city,
          state: state,
          country: country,
          postal_code: postalCode,
        },
      },
      // Puedes agregar otros campos opcionales según sea necesario
    };

    const storedToken = sessionStorage.getItem("userData");

    try {
      const response = await axios.patch(
        `${apiUrl}/api/v1/profile`,
        profileData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${storedToken}`,  // Token de autorización
          },
        }
      );

      if (response.data) {
        setMessage("Datos actualizados con éxito");
        setOpen(true);
      }
 
    } catch (error) {
      setMessage("Error al actualizar los datos");
      setOpen(true);

    }
  };

  return (
    <Container maxWidth="sm">
      <h2>Datos Empresariales</h2>
      <form onSubmit={handleSubmit}>
        <TextField
          type="text"
          variant="outlined"
          color="secondary"
          label="Nombre Empresa"
          onChange={(e) => setEnterpriseName(e.target.value)}
          value={enterpriseName}
          fullWidth
          required
          sx={{ mb: 4 }}
        />

        <Stack spacing={2} direction="row" sx={{ mb: 4 }}>
          <TextField
            type="text"
            variant="outlined"
            color="secondary"
            label="Razón Social"
            onChange={(e) => setSocialReason(e.target.value)}
            value={socialReason}
            fullWidth
            required
          />
          <TextField
            type="tel"
            variant="outlined"
            color="secondary"
            label="Teléfono Empresa"
            onChange={(e) => setEnterprisePhone(e.target.value)}
            value={enterprisePhone}
            fullWidth
            required
          />
                    <TextField
            type="mail"
            variant="outlined"
            color="secondary"
            label="Email"
            onChange={(e) => setEnterpriseEmail(e.target.value)}
            value={enterpriseEmail}
            fullWidth
            required
          />
        </Stack>

        <TextField
          type="text"
          variant="outlined"
          color="secondary"
          label="RFC"
          onChange={(e) => setRfc(e.target.value)}
          value={rfc}
          fullWidth
          required
          sx={{ mb: 4 }}
        />

        <TextField
          type="text"
          variant="outlined"
          color="secondary"
          label="Calle"
          onChange={(e) => setStreet(e.target.value)}
          value={street}
          fullWidth
          required
          sx={{ mb: 4 }}
        />

        <Stack spacing={2} direction="row" sx={{ mb: 4 }}>
          <TextField
            type="text"
            variant="outlined"
            color="secondary"
            label="Número Exterior"
            onChange={(e) => setExtNumber(e.target.value)}
            value={extNumber}
            fullWidth
            required
          />
          <TextField
            type="text"
            variant="outlined"
            color="secondary"
            label="Número Interior"
            onChange={(e) => setIntNumber(e.target.value)}
            value={intNumber}
            fullWidth
          />
        </Stack>

        <Stack spacing={2} direction="row" sx={{ mb: 4 }}>
          <TextField
            type="text"
            variant="outlined"
            color="secondary"
            label="Colonia"
            onChange={(e) => setNeighborhood(e.target.value)}
            value={neighborhood}
            fullWidth
            required
          />
          <TextField
            type="text"
            variant="outlined"
            color="secondary"
            label="Municipio"
            onChange={(e) => setCity(e.target.value)}
            value={city}
            fullWidth
            required
          />
        </Stack>

        <Stack spacing={2} direction="row" sx={{ mb: 4 }}>
          <TextField
            type="text"
            variant="outlined"
            color="secondary"
            label="Estado"
            onChange={(e) => setState(e.target.value)}
            value={state}
            fullWidth
            required
          />
          <TextField
            type="text"
            variant="outlined"
            color="secondary"
            label="País"
            onChange={(e) => setCountry(e.target.value)}
            value={country}
            fullWidth
            required
          />
        </Stack>

        <TextField
          type="text"
          variant="outlined"
          color="secondary"
          label="Código Postal"
          onChange={(e) => setPostalCode(e.target.value)}
          value={postalCode}
          fullWidth
          required
          sx={{ mb: 4 }}
        />

        <Button variant="outlined" color="secondary" type="submit" fullWidth>
          Guardar
        </Button>
      </form>
      <SnackBar
        onClose={()=>setOpen(false)}
        open={open}
        message={message}
      ></SnackBar>
    </Container>
  );
};
