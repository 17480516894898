import { FC } from "react";
import CardCustom from "../../components/card";
import TitleValue from "../../components/title-value";
import CardBasicCustom from "../../components/card-basic";
import "./home.css";
import ChartCustom, { DataChart } from "../../components/chart";
import useFetchStatistics from "../../service/userStatistics/getStatistic";
import {
  faChartSimple,
  faFileContract,
} from "@fortawesome/free-solid-svg-icons";
import { CardBasicCustomProps } from "../../model/custom-card-basic";
import InfoIcon from '@mui/icons-material/Info';

const Home: FC = () => {
  const storedToken = sessionStorage.getItem("userData");

  const { statistics, loading, error } = useFetchStatistics(storedToken || "");

  const totalAmount = statistics
    ? parseFloat(statistics?.total_amount.toFixed(2))
    : 0;
  const beforeMonthAmount = statistics ? statistics?.total_amount_last_month : 0
  const lastMonthAmount = statistics
    ? parseFloat(statistics?.total_amount_current_month.toFixed(2))
    : 0;
  const totalCanceledAmount = statistics
    ? parseFloat(statistics?.total_canceled_amount.toFixed(2))
    : 0;

  // const monthTranslations: { [key: string]: string } = {
  //   January: "Enero",
  //   February: "Febrero",
  //   March: "Marzo",
  //   April: "Abril",
  //   May: "Mayo",
  //   June: "Junio",
  //   July: "Julio",
  //   August: "Agosto",
  //   September: "Septiembre",
  //   October: "Octubre",
  //   November: "Noviembre",
  //   December: "Diciembre",
  // };

  // const translateMonth = (month: string): string => {
  //   return monthTranslations[month] || month;
  // };



  // const mesesMinusculas: any = statistics?.monthly_transactions.map(transaction => transaction.month.toLowerCase());

  
  // const labels = statistics
  //   ? statistics.monthly_transactions.map((transaction) =>
  //       translateMonth(transaction.month)
  //     )
  //   : [];

  // const data = statistics
  //   ? statistics.monthly_transactions.map(
  //       (transaction) => transaction.transaction_count
  //     )
  //   : [];

  // const chartData: DataChart = {
  //   labels,
  //   datasets: [
  //     {
  //       fill: true,
  //       label: "Número de transacciones",
  //       data: data,
  //       borderColor: "rgb(0, 149, 255)",
  //       backgroundColor: "rgba(0, 149, 255, 0.5)",
  //     },
  //   ],
  // };

  // const chartBarData = { ...chartData };


const monthTranslations: { [key: string]: string } = {
  January: "Enero",
  February: "Febrero",
  March: "Marzo",
  April: "Abril",
  May: "Mayo",
  June: "Junio",
  July: "Julio",
  August: "Agosto",
  September: "Septiembre",
  October: "Octubre",
  November: "Noviembre",
  December: "Diciembre",
};

const translateMonth = (month: string): string => {
  return monthTranslations[month] || month;
};

// Mapeo para traducir y eliminar duplicados en los meses
const uniqueMonths = Array.from(
  new Set(
    statistics?.monthly_transactions.map((transaction) =>
      translateMonth(transaction.month).toLowerCase()
    )
  )
);

// Traducción y filtrado de las etiquetas para la gráfica
const labels = uniqueMonths.map(
  (month) => month.charAt(0).toUpperCase() + month.slice(1)
);

// Datos para la gráfica sin cambios
const data = statistics
  ? statistics?.monthly_transactions.map(
      (transaction) => transaction.transaction_count
    )
  : [];

const chartData: DataChart = {
  labels,
  datasets: [
    {
      fill: true,
      label: "Número de transacciones",
      data: data,
      borderColor: "rgb(0, 149, 255)",
      backgroundColor: "rgba(0, 149, 255, 0.5)",
    },
  ],
};

const chartBarData = { ...chartData };

  

  chartBarData.datasets = [
    {
      ...chartBarData.datasets[0],
      backgroundColor: "rgb(74, 181, 142)",
    },
    {
      ...chartBarData.datasets[0],
      backgroundColor: "rgb(255, 207, 0)",
    },
  ];


  const dataCard: CardBasicCustomProps[] = [
    {
      icon: faChartSimple,
      iconTooltip: <InfoIcon/>,
      titleTooltip: 'Este valor representa el monto total de las transacciones aprobadas durante mes. Indica cuánto se ha cargado exitosamente a las tarjetas de tus clientes.',
      value: `$${lastMonthAmount?.toFixed(2)}`,
      description: "Cargos en el mes actual",
      classCustom: "card-first",
    },

    {
      icon: faChartSimple,
      iconTooltip: <InfoIcon/>,
      titleTooltip: 'Este valor representa el monto total de las transacciones aprobadas durante mes pasado. Indica cuánto se ha cargado exitosamente a las tarjetas de tus clientes.',
      value: `$${beforeMonthAmount?.toFixed(2)}`,
      description: "Total de Cargos en el mes anterior",
      classCustom: "card-first",
    },
    // {
    //   icon: faFileContract,
    //   iconTooltip: faChartSimple,

    //   value: "$0",
    //   description: "Monto retenido",
    //   classCustom: "card-second",
    // },
    {
      icon: faChartSimple,
      iconTooltip: <InfoIcon/>,
      titleTooltip: 'Este valor refleja la cantidad total de transacciones que han sido rechazadas o canceladas. Las razones comunes incluyen fondos insuficientes, problemas con la tarjeta o cancelaciones manuales por parte del cliente o del sistema.',
      value: `$${!totalCanceledAmount ? 0 : totalCanceledAmount?.toFixed(1)}`,
      description: "Montos Cancelados",
      classCustom: "card-third",
    },
    // {
    //   icon: faChartSimple,
    //   value: `${newCustomers}`,
    //   description: "Nuevos Clientes",
    //   classCustom: "card-fourth",
    // },
  ];
  

  return (
    <div className="home">
      <div className="section1">
        <CardCustom>
          <TitleValue title="Cargos totales" value={totalAmount} />
          <div className="cards_home">
            {dataCard.map((card, index) => (
              <CardBasicCustom
                iconTooltip={card.iconTooltip}
                titleTooltip={card.titleTooltip}
                icon={card.icon}
                value={card.value}
                description={card.description}
                info={card.info}
                key={index}
                classCustom={card.classCustom}
              />
            ))}
          </div>
        </CardCustom>
      </div>
      <div className="graphics_home">
        <CardCustom classCustom="card_chart_bar">
          <ChartCustom
            title="Transacciones en los ultimos meses"
            type="bar"
            data={chartData}
          />
        </CardCustom>
        {/* <CardCustom classCustom="card_chart_bar">
          <ChartCustom title="Estadisticas" type="line" data={chartData} />
        </CardCustom> */}
        {/* <CardCustom classCustom="card_chart_bar">
                    <ChartCustom title="Target vs Reality" type="bar" data={chartBarData}>
                        <div className="content_card">
                        </div>
                    </ChartCustom>
                </CardCustom> */}
      </div>
    </div>
  );
};

export default Home;
