import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./home";
import { useAuth0 } from "@auth0/auth0-react";
import NotFound from "./not-found";
import Layout from "../components/layout";
import Activity from "./activity";
import Settings from "./settings";
import Profile from "./profile";
import Transaction from "./transaction";
import Integration from "./integration";
import BankAccount from "./bankaccount";
import Tranference from "./tranference";

const AppRoutes: React.FC = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="page-layout">
        cargando....
        {/* <PageLoader /> */}
      </div>
    );
  }
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/activity" element={<Activity />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/transactions" element={<Transaction />} />
        <Route path="/transference" element={<Tranference />} />

        <Route path="/settings" element={<Settings />} />
        <Route path="/bankaccount" element={<BankAccount />} />

        <Route path="/integrations" element={<Integration />} />
      </Routes>
    </Layout>
  );
};

export default AppRoutes;
