import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { FormUser } from "./FormUser";
import { FormEnterprise } from "./FormEnteprise";
import { useAuth0 } from "@auth0/auth0-react";
import CardCustom from "../../components/card";
import { Avatar, Container, Grid } from "@mui/material";
import "./profile.css";
import userFetchProfile from "../../service/userProfile/userProfile";

const Profile = () => {
  const { user } = useAuth0();

  const [expanded, setExpanded] = React.useState<string | false>(false);

  // const url = `${process.env.REACT_APP_API_URL}/api/v1/auth?email=${user?.email}`;

  // const { data: profile, error, loading, fetchData } = useAxios<any>(url, 'GET');

  // const { data: getProfile, fetchData: getData } = useAxios<any>(urlProfile, 'GET', {
  //   headers: {
  //      Authorization: `Bearer ${useToken}`
  //   }
  // });

  const storedToken = sessionStorage.getItem("userData");

  const { profile, loading, error } = userFetchProfile(storedToken || "");


  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12}>
            <CardCustom classCustom="card-profile-custom">
              <Avatar
                sx={{ width: 52, height: 52, marginBottom: "10px" }}
                src={user?.picture}
              ></Avatar>

              <Typography>{user?.name}</Typography>
            </CardCustom>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Datos Personales
                </Typography>
                <Typography sx={{ color: "text.secondary" }}></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormUser data={profile || []} />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Datos de empresa
                </Typography>
                <Typography sx={{ color: "text.secondary" }}></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormEnterprise data={profile || []} />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Container>

      {/* <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            Advanced settings
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            Filtering has been entirely disabled for whole web server
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer
            sit amet egestas eros, vitae egestas augue. Duis vel est augue.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            Personal data
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer
            sit amet egestas eros, vitae egestas augue. Duis vel est augue.
          </Typography>
        </AccordionDetails>
      </Accordion> */}
    </div>
  );
};

export default Profile;
