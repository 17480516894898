import React from 'react';
import  Lottie  from 'lottie-react';
import { Typography } from '@mui/material';
import '../loadingLotties/lottiesComponent.css'
interface PropslottiesComponent{
    lotties?: any,
    title?: string,
    style?: any

}


export const LottiesComponent = ({lotties,title, style}: PropslottiesComponent) => {
  return (
    <div className='main-lotties-container' >
      <Lottie animationData={lotties} style={style} loop={true} autoplay={true} />
      <Typography variant="h4" component="h2" align='center'>{title}</Typography>
    </div>
  )
}
