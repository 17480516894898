// hooks/useFetchTransactions.ts
import { useState, useEffect } from "react";
import axios from "axios";
import { TransactionResponse, Transactions } from "../../const/transaction";

const apiUrl = process.env.REACT_APP_API_URL;

const useFetchTransactions = (
  token: any,
  _page: number,
  _pageSize: number,
  filter: any
) => {
  const [transactions, setTransactions] = useState<Transactions[] | any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);


  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.post<TransactionResponse>(
          `${apiUrl}/api/v1/transaction/search`, 
          {
            filters: filter,
            pagination: {
              page: _page,
              pageSize: _pageSize,
            }
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        setTransactions(response?.data);
      } catch (err) {
        console.error("Error fetching transactions:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [token, _page, _pageSize, filter]);

  return { transactions, loading, error };
};

export default useFetchTransactions;
