import { faAddressCard, faBagShopping, faCartShopping, faChartLine, faChartPie, faChartSimple, faCreditCard, faGear, faGears, faMoneyBillTransfer, faUser } from "@fortawesome/free-solid-svg-icons";
import { CustomRouter } from "../model/custom-router.model";
import { faCommentDots } from "@fortawesome/free-regular-svg-icons";

export const MENU: CustomRouter[] = [
    {
        to: '/',
        text: 'Inicio',
        icon: faChartPie
    },
    // {
    //     to: '/activity',
    //     text: 'Actividad',
    //     icon: faChartSimple
    // },
    {
        to: '/transactions',
        text: 'Transacciones',
        icon: faCreditCard
    },
    // {
    //     to: '/transference',
    //     text: 'Transferencias',
    //     icon: faMoneyBillTransfer
    // },
    // {
    //     to: '/bankaccount',
    //     text: 'Cuentas Bancarias',
    //     icon: faCartShopping
    // },

    // {
    //     to: '/transfer',
    //     text: 'Retiros',
    //     icon: faCartShopping
    // },
    // {
    //     to: '/products',
    //     text: 'Productos',
    //     icon: faBagShopping
    // },
    // {
    //     to: '/sales-report',
    //     text: 'Reporte de ventas',
    //     icon: faChartLine
    // },
    // {
    //     to: '/messages',
    //     text: 'Mensajes',
    //     icon: faCommentDots
    // },
    {
        to: '/profile',
        text: 'Mi perfil',
        icon: faUser
    },
    // {
    //     to: '/credentials',
    //     text: 'Credenciales',
    //     icon: faAddressCard
    // },
    {
        to: '/integrations',
        text: 'Integraciones',
        icon: faGears
    },
    {
        to: '/settings',
        text: 'Configuraciones',
        icon: faGear
    },
    // {
    //     text: 'Sign Out',
    //     icon: faRightFromBracket,
    //     click: handleLogout
    // }
];