// hooks/useFetchCustomers.ts
import { useState, useEffect } from "react";
import axios from "axios";
import { Customer } from "../../const/customer";

const apiUrl = process.env.REACT_APP_API_URL;

const useFetchCustomers = (token: string) => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get<{ data: Customer[] }>(
          `${apiUrl}/api/v1/customer`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCustomers(response.data.data);
      } catch (err) {
        setError("Error fetching customers");
      } finally {
        setLoading(false);
      }
    };

    fetchCustomers();
  }, [token]);

  return { customers, loading, error };
};

export default useFetchCustomers;
