import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface ModalProps {
  setOpen?: any;
  open?: boolean;
  children?: any
  data?: Array<any> | any
  title?: string
}

export default function ModalUI({ setOpen, open, children, title }: ModalProps) {
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Dialog
        maxWidth="sm" 
        fullWidth
        open={open || false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <div>
          {children ? children : <></>}

          </div>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleClose} autoFocus>
            Aceptar
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
