import { FC } from "react";
import './title-value.css';

interface TitleValueProps {
    title: string;
    value: number;
}
 
const TitleValue: FC<TitleValueProps> = ({ title, value }) => {
    return (
        <div className="title_value">
            <h3 className="title_value_title">{title}</h3>
            <p className="title_value_value">${value}</p>
        </div>
    );
}
 
export default TitleValue;