import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "../inputsDatePicker/inputDatepicker.css";
import { es } from "date-fns/locale";

interface InputPicker {
  startDate?: Date | null;
  endDate?: Date | null;
  setStartDate?: any;
  setEndDate?: any;
}

export const InputDatePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: InputPicker) => {
  const handleDateStartChange = (date: Date | null) => {
    setStartDate(date);
  };

  const handleDateEndChange = (date: Date | null) => {
    setEndDate(date);
  };

  return (
    <>
      <Grid item xs={12} sm={2} rowSpacing={2}>
        <Box>
          <Typography>Del</Typography>
          <DatePicker
            locale="es"
             dateFormat="dd/MM/yyyy"
            selected={startDate}
            onChange={(date) => handleDateStartChange(date)}
            placeholderText="Seleccione una fecha"
          />
        </Box>
      </Grid>
      <Grid xs={12} sm={2}>
        <Box>
          <Typography>Hasta</Typography>
          <DatePicker
            locale="es"
             dateFormat="dd/MM/yyyy"
            placeholderText="Seleccione una fecha"
            selected={endDate}
            onChange={(date) => handleDateEndChange(date)}
          />
        </Box>
      </Grid>
    </>
  );
};
