import React from 'react';
import  Lottie  from 'lottie-react';
import loadingPage from '../assets/lottie/loading.json'; // Ajusta la ruta a tu archivo JSON
import './loading.css'


const LoadingScreen: React.FC = () => {
  return (
    <div className="loading-screen">
      <Lottie animationData={loadingPage} loop={true} autoplay={true} />
    </div>
  );
};

export default LoadingScreen;