import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

interface TooltipProps{
  title?: string,
  icon?: any, 
}

export default function TooltipUI({title, icon}:TooltipProps) {
  return (
    <Tooltip title={title ?? ''}>
      <IconButton>
      { icon ?? <></>}
      </IconButton>
    </Tooltip>
  );
}