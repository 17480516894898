import { useState, useEffect } from 'react';
import axios from 'axios';

interface UserData {
  decodedToken: {
    user: {
      email: string;
    };
  };
}

const apiUrl = process.env.REACT_APP_API_URL;

const useAuthToken = (clientId: string) => {
  const [token, setToken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchToken = async () => {
      const storedToken = sessionStorage.getItem("userData");

      if (storedToken) {
        setToken(storedToken);
        setLoading(false);
        return;
      }

      const storedData = localStorage.getItem(`@@auth0spajs@@::${clientId}::@@user@@`);

      if (!storedData) {
        setLoading(false);
        return;
      }

      const userData: UserData = JSON.parse(storedData);
      const userEmail = userData.decodedToken.user.email;
      const getAuthApiUrl = `${apiUrl}/api/v1/auth?email=${userEmail}`;

      try {
        const { data } = await axios.get<{ token: string }>(getAuthApiUrl);
        sessionStorage.setItem("userData", JSON.parse(JSON.stringify(data.token)));
        setToken(data.token);
      } catch (error) {
        console.error('Error fetching token:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchToken();
  }, [clientId]);

  return { token, loading };
};

export default useAuthToken;
