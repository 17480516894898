import React, { useState } from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { green } from "@mui/material/colors";

interface FormData {
  name: string;
  account: string;
  typeOfAccount: string;
  bank: string;
}

const accountType = ["Corriente", "Ahorro", "Nómina"];
const banks = ["Banco A", "Banco B", "Banco C", "Banco D"];

const theme = createTheme({
  palette: {
    primary: {
      main: green[700],
    },
    secondary: {
      main: green[500],
    },
    text: {
      primary: green[900],
      secondary: green[700],
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          color: "white",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            borderRadius: 10,
            "& fieldset": {
              borderColor: green[200],
            },
            "&:hover fieldset": {
              borderColor: green[400],
            },
            "&.Mui-focused fieldset": {
              borderColor: green[500],
            },
          },
          "& .MuiInputLabel-root": {
            color: green[700],
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: 10,
            borderColor: green[200],
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: green[400],
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: green[500],
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: green[700],
        },
      },
    },
  },
});

export default function AccountFormulary() {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    name: "",
    account: "",
    typeOfAccount: "",
    bank: "",
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleClose();
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Button
          variant="contained"
          onClick={handleOpen}
          sx={{
            fontSize: "1rem",
            padding: "10px 20px",
            textTransform: "none",
            backgroundColor: green[600],
            "&:hover": {
              backgroundColor: green[700],
            },
          }}
        >
          Agregar Cuenta
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 4,
            }}
          >
            <Typography
              id="modal-title"
              variant="h5"
              component="h2"
              gutterBottom
              sx={{ color: "primary.main", fontWeight: "bold" }}
            >
              Agregar Nueva Cuenta
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                margin="normal"
                label="Nombre"
                name="nombre"
                value={formData.name}
                onChange={handleChange}
                required
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Cuenta"
                name="cuenta"
                value={formData.account}
                onChange={handleChange}
                required
                sx={{ mb: 2 }}
              />
              <FormControl fullWidth margin="normal" required sx={{ mb: 2 }}>
                <InputLabel id="tipo-cuenta-label">Tipo de Cuenta</InputLabel>
                <Select
                  labelId="tipo-cuenta-label"
                  name="tipoCuenta"
                  value={formData.typeOfAccount}
                  onChange={handleSelectChange}
                  label="Tipo de Cuenta"
                >
                  {accountType.map((tipo) => (
                    <MenuItem key={tipo} value={tipo}>
                      {tipo}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal" required sx={{ mb: 3 }}>
                <InputLabel id="banco-label">Banco</InputLabel>
                <Select
                  labelId="banco-label"
                  name="banco"
                  value={formData.bank}
                  onChange={handleSelectChange}
                  label="Banco"
                >
                  {banks.map((banco) => (
                    <MenuItem key={banco} value={banco}>
                      {banco}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  mt: 2,
                  py: 1.5,
                  fontSize: "1rem",
                  textTransform: "none",
                  backgroundColor: green[600],
                  "&:hover": {
                    backgroundColor: green[700],
                  },
                }}
              >
                Guardar Cuenta
              </Button>
            </form>
          </Box>
        </Modal>
      </div>
    </ThemeProvider>
  );
}
