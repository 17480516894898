import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";


export const TransactionModal = ({ data }: any) => {

  function createData(name: string, calories: any) {
    return { name, calories };
  }

  const checkTransaction = (data: any) => {
    console.log(typeof data?.metadata?.PROCESSOR_ADVICE);
    if (
      data?.metadata?.PROCESSOR_ADVICE !== " " &&
      data?.metadata?.PROCESSOR_ADVICE !== undefined
    ) {
      return data.metadata.PROCESSOR_ADVICE;
    }

    if (data?.metadata?.PROCESSOR_ADVICE === undefined) {
      return "Transacción aprobada por el banco";
    }

    if (data?.status === "APPROVED") {
      return "Transacción aprobada por el banco";
    }

    return "Fallo al momento de realizar la transacción";
  };

  const translations: { [key: string]: string } = {
    APPROVED: "Aprobado",
    DECLINED: "Rechazado",
    PENDING: "Pendiente",
    FAILED: "Rechazado",
    charge_pending: "Pendiente",
  };

  const translateStatus = (status: string): string => {
    return translations[status] || status;
  };



  const rows = [
    createData("Nombre de cliente:", data?.full_name),
    createData("Email:", data?.email),
    createData("Nombre de banco:", data?.card_brand),
    createData("Cobro:", "$ " + data?.amount),
    createData("Comisión:", "$ " + data?.amount_with_comision),
    createData(
      "Fecha:",
      new Date(data?.created_at).toLocaleDateString("es-MX", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZone: "America/Mexico_City",
      })
    ),
    createData("Rastreo:", data?._id),
    createData("Estatus:", translateStatus(data?.status) ),
    createData("Estado de transacción:", data?.status_description),
    createData("Respuesta de banco:", checkTransaction(data)),
  ];

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 400 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">Información</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell style={{fontWeight:'bold'}} component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.calories}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
