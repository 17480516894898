import { FC, ReactNode } from "react";
import { MENU } from "../../const/menu.const";
import CustomHeader from "./header";
import logo from "./../../assets/logo.png";
import "./layout.css";
import NavBarCustom from "../navbar";

interface LayoutProps {
  children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const optionsMenu = MENU;
  return (
    <div className="content">
      <aside className="menu-vertical">
        <div className="logo">
          <img src={logo} alt="logo" className="img" />
        </div>
        <NavBarCustom routers={optionsMenu} />
      </aside>
      <div className="container">
        <CustomHeader />
        <div className="view-container">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
