import { useAuth0 } from "@auth0/auth0-react";
import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { FC } from "react";
import React from "react";

interface MenuButtonProps {
  handleClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  name: string | undefined;
  picture: string | undefined;
}

export const MenuButton: FC<MenuButtonProps> = ({
  handleClick,
  name,
  picture,
}) => {
  const { logout } = useAuth0();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickT = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title={name}>
        <IconButton
          onClick={handleClickT}
          size="small"
          sx={{ ml: 2 }}
          // aria-controls={open ? "account-menu" : undefined}
          // aria-haspopup="true"
          // aria-expanded={open ? "true" : undefined}
        >
          <Avatar sx={{ width: 32, height: 32 }} src={picture}></Avatar>
        </IconButton>
      </Tooltip>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() =>
            logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            })
          }
        >
         Cerrar Sesion
        </MenuItem>
      </Menu>
    </div>
  );
};
