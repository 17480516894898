import { useState } from "react";
import "./activity.css";
import CardCustom from "../../components/card";
import TableCustom, { TableCustomProps } from "../../components/table/table";
import PaginationCustom from "../../components/pagination";
import { LottiesComponent } from "../../components/loadingLotties/LottiesComponent";
import userLottie from "../../assets/lottie/userSearch.json";
import { Customer } from "../../const/customer";
import useFetchCustomers from "../../service/userTransactions/getCostumer";
import TableMaterial from "../../components/tableMaterial/tableMaterial";

const transformDataToTable = (
  customers: Customer[]
): TableCustomProps["rows"] => {
  return customers.map((customer) => ({
    email: customer.email,
    profileId: customer.profileId,
  }));
};

const Activity = () => {
  const storedToken = sessionStorage.getItem("userData");
  const { customers, loading, error } = useFetchCustomers(storedToken || "");

  const [page, setPage] = useState(1);
  const itemsPerPage = 8;

  const dataTable: any = {
    columns: [
      { id: "email", label: "Correo" },
      { id: "profileId", label: "ID de Usuarios" },
    ],
    rows: transformDataToTable(customers),
  };

  // const totalPages = Math.ceil(dataTable.rows.length / itemsPerPage);
  // const paginatedRows = dataTable.rows.slice(
  //   (page - 1) * itemsPerPage,
  //   page * itemsPerPage
  // );

  // const handlePageChange = (
  //   event: React.ChangeEvent<unknown>,
  //   value: number
  // ) => {
  //   setPage(value);
  // };

  const hasData = dataTable.rows.length > 0;

  return (
    <div className="activity">
      {hasData ? (
        <CardCustom >
          <h2>ACTIVIDAD DE USUARIOS</h2>
          <TableMaterial columns={dataTable.columns || []} rows={dataTable.rows}/>
          {/* <div className="activity_pagination">
            <p className="activity_pagination_description">
              Página {page} de {totalPages}
            </p>
            <PaginationCustom
              count={totalPages}
              page={page}
              onChange={handlePageChange}
            />
          </div> */}
        </CardCustom>
      ) : (
        <LottiesComponent
          title="Vaya! Aun no tienes clientes registrados. Integra nuestra API en tu sitio y administra tu primeros clientes"
          lotties={userLottie}
        />
      )}
    </div>
  );
};

export default Activity;
