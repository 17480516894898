import { useMemo, useState } from "react";
import "./transaction.css";
import CardCustom from "../../components/card";
import { TableCustomProps } from "../../components/table/table";
import { LottiesComponent } from "../../components/loadingLotties/LottiesComponent";
import cardLottie from "../../assets/lottie/target.json";
import useFetchTransactions from "../../service/userTransactions/getTransaction";
import TableMaterial from "../../components/tableMaterial/tableMaterial";
import ModalUI from "../../components/modalUI/ModalUI";
import { TransactionModal } from "./transactionModal/TransactionModal";
import ButtonUI from "../../components/button/buttons";
import InfoIcon from "@mui/icons-material/Info";
import { newDate } from "react-datepicker/dist/date_utils";

const Transaction = () => {
  const storedToken = sessionStorage.getItem("userData");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endStarDate, setEndStartDate] = useState<Date | null>(null);
  const [selectedTransaction, setSelectTransaction] = useState<null>(null);

  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [searchClicked, setSearchClicked] = useState(false);



  const handleOpenModal = (transaction: any) => {
    setOpenModal(true);
    setSelectTransaction(transaction);
    // setOpenModal(true);
  };

  const transformDataToTable = (
    transactions: any[]
  ): TableCustomProps["rows"] => {
    const translations: { [key: string]: string } = {
      APPROVED: "Aprobado",
      DECLINED: "Rechazado",
      PENDING: "Pendiente",
      FAILED: "Rechazado",
      charge_pending: "Pendiente",
    };

    const translateStatus = (status: string): string => {
      return translations[status] || status;
    };

    return transactions.map((transaction) => ({
      name: transaction.full_name,
      company: transaction.card_brand,
      activity: transaction.status_description,
      email: transaction.email,
      amount: "$ " + transaction.amount,
      comision: "$ " + transaction.amount_with_comision,
      country: transaction.currency,
      date: new Date(transaction?.created_at).toLocaleDateString("es-MX", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZone: "America/Mexico_City",
      }),
      status: (
        <div className={`status ${transaction.status.toLowerCase()}`}>
          {translateStatus(transaction.status)}
        </div>
      ),

      info: (
        <div>
          <ButtonUI
            icon={<InfoIcon />}
            variant="text"
            onClick={() => handleOpenModal(transaction)}
          />
        </div>
      ),
    }));
  };

  const buildFilters = (
    email?: string,
    startDate?: any,
    endDate?: any
  ): any[] => {
    const filters = [];
    if (email) filters.push({ key: "email", value: email });
    if (startDate)
      filters.push({
        key: "created_at",
        value: new Date(startDate),
        operator: "$gte",
      });
    if (endDate)
      filters.push({
        key: "created_at",
        value: new Date(endDate),
        operator: "$lte",
      });
    return filters;
  };

  const filter = useMemo(
    () => buildFilters(email, startDate, endStarDate),
    [startDate, endStarDate,searchClicked]
  );

  const { transactions, loading, error } = useFetchTransactions(
    storedToken || "",
    page,
    rowsPerPage,
    filter
  );

  const dataTable: any = {
    columns: [
      { id: "name", label: "Nombre" },
      { id: "company", label: "Banco" },
      { id: "activity", label: "Respuesta de banco" },
      { id: "email", label: "Correo" },
      { id: "amount", label: "Pago" },
      { id: "comision", label: "Comision" },
      { id: "country", label: "Moneda" },
      { id: "date", label: "Fecha" },
      { id: "status", label: "Status" },
      { id: "info", label: "info" },
    ],
    rows: transformDataToTable(transactions?.response?.data || []),
  };

  const hasData = transactions;

  const onChangeEmail = (e: any) => {

      setEmail(e.target.value);
  
  };

  const handleSearchClick = () => {
    setSearchClicked(prev => !prev);
  };

  return (
    <div>
      {hasData ? (
        <CardCustom classCustom="activity_card">
          <h2>Transacciones</h2>
          <TableMaterial

            valueInput={email}
            onClickSearch={handleSearchClick}
            onChangeInput={onChangeEmail}
            isDate={true}
            setEndDate={setEndStartDate}
            setStartDate={setStartDate}
            startDate={startDate}
            endDate={endStarDate}
            totalRows={transactions?.response?.pagination?.countData}
            setPage={setPage}
            page={page}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            report={true}
            columns={dataTable.columns}
            rows={dataTable?.rows || []}
            loading={loading}
          />
        </CardCustom>
      ) : (
        <LottiesComponent
          style={{ width: "400px" }}
          title="Aun no tienes transacciones. Integra nuestra API en tu sitio y administra tus primeras transacciones"
          lotties={cardLottie}
        />
      )}
      <ModalUI
        children={<TransactionModal data={selectedTransaction || []} />}
        title="Información de transacción"
        open={openModal}
        setOpen={setOpenModal}
      />
    </div>
  );
};

export default Transaction;
