// hooks/useFetchStatistics.ts
import { useState, useEffect } from "react";
import axios from "axios";
import { Statistics } from "../../const/statistic.const";

const apiUrl = process.env.REACT_APP_API_URL;

const useFetchStatistics = (token: string) => {
  const [transference, setTransference] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined | null>(null);

  useEffect(() => {
    const fetchTranference= async () => {
      try {
        const response = await axios.get<{ data: any }>(
          `${apiUrl}/api/v1/transference`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTransference(response.data.data);
      } catch (err) {
        setError("Error fetching statistics");
      } finally {
        setLoading(false);
      }
    };

    fetchTranference();
  }, [token]);

  return { transference, loading, error };
};

export default useFetchStatistics;
